import {React, Suspense} from 'react'
import {Canvas} from '@react-three/fiber'
import {createUseStyles} from 'react-jss'
import Model from './model.jsx'
import { OrbitControls } from '@react-three/drei'
import { styleDefaults } from '../globals/globals.jsx'

const Homepage = () => {
    const styles = useStyles()
    return (
        <div className={styles.productCanvas}>
            <div className={styles.mainTextbox}>
                Hi, I am Simon, a driven engineer, with 5 years of experience in manufacturing at small and large start-ups, while I consider myself someone who solves difficult problems for start-ups, my current expertise is in the creation of data collection and management systems for manufacturing facilities and laboratories.
            </div> 
            {/* <Canvas shadowMap camera = {{ position: [0,-18,0] }}> */}
            <Canvas camera = {{ position: [0,-1, 0.25] }} backgroundColor = 'black'>
                <Suspense fallback={null}>
                    <ambientLight />
                    <spotLight 
                        intensity = {1}
                        position = {[-2,0,5]}
                    />
                    <rectAreaLight
                        width = {3}
                        height = {3}
                        intensity = {20}
                        angle = {0.1}
                        color ='white'
                        penumbra = {1}
                        position = {[-2,0,5]}
                        lookAt={[0,0,0]}
                        castShadow />
                    <Model />
                    <OrbitControls 
                        enablePan={true}
                        enableZoom={true}
                        enableRotate={true}/>
                </Suspense>
            </Canvas>
            <div className={styles.modelNotification}>
                Click and drag the model to interact
            </div>
        </div>
    );
};

const style = styleDefaults()

const useStyles = createUseStyles({
    mainTextbox: {
        width: "100%",
        padding: "10px",
        textAlign: 'justify',
        margin: 'auto auto auto auto',
        //position: 'absolute',
        color: style.textColor,
        textShadow: style.textShadow,
        fontSize: style.headingFontSize

    },
    productCanvas: {
        maxWidth: '1100px',
        width: '95vw',
        height: '60vh',
        margin: 'auto auto auto auto',
        borderRadius: '2px',
        //marginBottom: '20px'
    },
        modelNotification: {
        textAlign: 'right',
        fontSize: style.heading2FontSize,
        textShadow: style.textShadow,
        color: style.textColor2,
    }

});

export default Homepage