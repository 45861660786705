import {React, useState} from 'react'
import {createUseStyles } from 'react-jss'
import { styleDefaults } from '../../globals/globals.jsx'
//import {Canvas} from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import lightAlignmentTesting from './images/lightAlignmentTesting.jpg'
import secAssembled from './images/secAssembled.jpg'
import secInUse from './images/secInUse.jpeg'
import SecOld from './models/secOld.jsx'
import SecNew from './models/secNew.jsx'
import styled from "styled-components";


const Sec = () => {
    const styles = useStyles()
    const [state, setState] = useState({
        tab: types[0].name
    })
    return(
        <div className = {styles.body}>
            <div className = {styles.title}>
                Smart Eye Camera Design Optimization and Manufacturing
            </div>
            <img className  = {styles.mainImage} src = {secAssembled}/>
            <hr className = {styles.hrStyle} />
            <div className= {styles.sectionHeading}>
                BACKGROUND
            </div>
            <div className = {styles.sectionBody}>

            <a target="_blank" href='https://ouiinc.jp/en/top' style = {{color:style.linkColor}} >OUI</a> is a Japanese medical startup focused on providing low cost ophthalmological devices around the world.
            They developed the Smart Eye Camera (SEC), a smart phone attachment to diagnose many common eye diseases.
            While possessing a functional prototype, the company required manufacturing and assembly services as well as some design optimization, to be brought to market.
            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                REQUIREMENTS
            </div>
            <div className = {styles.sectionBody}> 
                Test and optimize the design and manufacturing process of the SEC to bring it to market for small volumes (fewer than 10,000 units) and produce an initial 100 units in Kisumu, Kenya and export to Tokyo, Japan for delivery. 
            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                PRODUCT TESTING
            </div>
            <img className  = {styles.mainImage} src = {lightAlignmentTesting}/>
            <div className = {styles.sectionBody}>
                Working directly with OUI, I developed testing protocols and devices to ensure the final product meets the necessary requirements.
                This included: life cycle testing, testing the optical alignment, and ensuring necessary tolerances. 
            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                DESIGN MODIFICATIONS
            </div>
            <div className={styles.modelGroup}>
                <div className={styles.modelNotification}>
                    Click and drag the model and select bottom tabs to interact
                </div>
                {/* <div className={styles.sectionHeading}>
                Original Model
                </div>
                <SecOld selected={state.tab}/> */}
                <div className={styles.sectionHeading2} >
                Optimized Model
                </div>
                <SecNew selected={state.tab}/>
            </div>
            <TabGroup
                tab = {state.tab}
                setTab = {(newTab) => setState((s) => ({...s, tab: newTab}))}
            />
            <DesModText
                tab = {state.tab}
                setTab = {(newTab) => setState((s) => ({...s, tab: newTab}))}    
            />
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                ASSEMBLY AND QUALITY ASSURANCE
            </div>
            <div className = {styles.sectionBody}>
                I developed detailed assembly and QA processes to meet product standards.
                This involved developing a process to check standard print errors, including surface quality and tolerances.
                Additionally, I developed new post-assembly functionality testing protocol, testing the slides and the optical alignment of the device. 
            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                IMPACT
            </div>
            <img className  = {styles.mainImage} src = {secInUse}/>
            <div className = {styles.sectionBody}>
                I managed the manufacturing and assembly processes to successfully deliver the product. It met the customer requirements and future orders are expected.
                Currently the product is in use all around the world including Japan, Vietnam, and many parts of Sub-Saharan Africa.
            </div>
        </div>
    )
}


const Tab = styled.button`
  padding: 10px 10px;
  cursor: pointer;
  opacity: 0.6;
  background: #E8E8E8;
  border: 0;
  outline: 0;
  border-bottom: 2px solid transparent;
  transition: ease border-bottom 2ms;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid black;
    opacity: 1;
  `}
`;

export const types = [
    {name: "Default", lockColor: "#484848", lensUnitColor: "#484848", rotation: [4.5,0,0], target: [0, 1.75, 0]},
    {name: "Locking Mechanism", lockColor: "red", lensUnitColor: "#484848", rotation: [5,0,-1.5], target: [0, 1.75, 0]},
    {name: "Lens Unit", lockColor: "#484848", lensUnitColor: "red", rotation: [4.5,-0.02,0.1], target: [-2, 1.75, 0],}
];

const TabGroup = (props) => {
    const {tab, setTab} = props
    const names = types.map(x => x.name)
    const styles=useStyles()
    return (
      <>
        <div>
          {names.map((type) => {
            return (
            <Tab
                className={styles.tabGroup}
                key={type}
              active={tab === type}
              onClick={() => setTab(type)}
            >
              {type}
            </Tab>
            )
          })}
        </div>
        <p/>
      </>
    );
  }

const DesModText = (props) => {
    const styles = useStyles()
    const {tab, setTab} = props
    const names = types.map(x => x.name)
    switch(tab) {
        case 'Default':
            return (
                <div className = {styles.sectionBody}>
                    To produce the best product, I modified the design to ensure manufacturability, acceptable tolerances, and optimized assembly process.
                    Due to the complexity, size, and order volume, I chose a combination of stereolithography (SLA) and laser cutting for the manufacturing process. 
                    To accommodate the shrinkages in the curing process of SLA, the product required scaling and tolerance adjustments to achieve smooth motion and proper fitting with the smart phone.
                    Additionally, two other major modifications were made to the design to improve its function: strengthening of the locking mechanism and adjustment of the lens unit to improve the assembly process.
                    Read more about these modifications by selecting the above tabs. 
                </div>
            )
        case 'Locking Mechanism':
            return (
                <div className = {styles.sectionBody}>
                    During product testing I realized the compliant mechanism locking the lens assembly in place could would easily break if the product was used slightly incorrectly and could not withstand the desired life cycles.
                    To combat this the mechanism was extended throughout the depth of the base. This increased the surface area which both reduced the shear stress, and distributing some of it into torsional stress.
                    This modification eliminated the issue of breakage.
                </div>
            )
        case 'Lens Unit':
            return (
                <div className = {styles.sectionBody}>
                    I made modifications to the lens unit to allow for more tolerance for lens imperfections and simplifying assembly. 
                </div>
            )
    }
    return(
        <div className = {styles.sectionBody}>

        </div>)
}

const style = styleDefaults()

const useStyles = createUseStyles({
    mainImage: {
        width: '60%',
        margin: 'auto auto 20px 20%',
        //border: 'solid',
        //borderColor: style.borderColor,
        boxShadow: style.boxShadow
    },
    imageFlat: {
        width: '90%',
        margin: 'auto auto 20px 5%',
        //border: 'solid',
        //borderColor: style.borderColor,
        boxShadow: style.boxShadow
    },
    sectionHeading: {
        width: '100%',
        fontSize: style.headingFontSize,
        margin:'auto auto 10px auto',
        color: style.textColor,
        //color: color,
        textShadow: style.textShadow,
        fontWeight: '700',
        //textAlign: 'center',
        textDecoration: 'none'
    },
    sectionHeading2: {
        width: '100%',
        fontSize: style.heading2FontSize,
        margin:'auto auto 10px auto',
        color: style.textColor,
        //color: color,
        textShadow: style.textShadow,
        fontWeight: '700',
        //textAlign: 'center',
        textDecoration: 'none'
    },
    sectionBody:{
        width: '100%',
        margin: 'auto auto 20px auto',
        fontSize: style.bodyFontSize,
        color: style.textColor,
        textShadow: style.textShadow,
        textAlign: 'justify',
        //backgroundColor: style.accentColor2,
    },
    hrStyle:{
        width: '100%',
        margin: 'auto auto 10px auto',
        backgroundColor: style.accentColor,
        height: '1pt',
        border: 'none',
        borderColor: style.accentColor
    },
    title:{
        width: '100%',
        textAlign: 'center',
        fontSize: style.titleFontSize,
        margin: 'auto auto 20px auto',
        fontWeight: '700',
        color: style.textColor
    },
    body:{
        width: '95%',
        maxWidth: "1100px",
        margin: 'auto auto auto auto'
    },
    tabGroup:{
        margin: 'auto auto auto auto',
        fontSize: style.bodyFontSize
        //fontSize: '40 pt'
    },
    modelGroup: {
        height: '50vh',
        //border: 'solid',
        margin: '0 0 10px 0'
    },
    modelNotification: {
        textAlign: 'right',
        fontSize: style.heading2FontSize,
        textShadow: style.textShadow,
        color: style.textColor2,
        fontWeight: '700'
    },

    link: {
        color: "red"
    }
})


export default Sec