import React from "react";
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import AcademicResearch from "./AcademicResearch/academicResearch.jsx";
import Navbar from "./components/navbar.jsx";
import Homepage from "./homepage/homepage.jsx";
import Kijenzi from "./Kijenzi/kijenzi.jsx";
import Reliefbot from "./AcademicResearch/reliefbot/reliefbot.jsx"
import Scoop from "./Kijenzi/scoop/scoop.jsx"
import Sec from "./Kijenzi/sec/sec.jsx"
import Resume from "./Resume/resume.jsx"
import CostEstimation from "./Kijenzi/costEstimation/costEstimation.jsx"
import Erpnext from "./Kijenzi/erpnext/erpnext.jsx"
import Thesis from "./AcademicResearch/thesis/thesis.jsx"
import FormEnergy from "./Form Energy/formenergy.jsx"

export const Switch = () => {
    const history = useNavigate()
    return (
        <div>
            <Navbar history = {history}/>
            <Routes>
                <Route path="/" element = {<Homepage/>}>
                    <Route path = '/home' element = {<Homepage/>}/>                  
                </Route>
                <Route path = '/kijenzi' element = {<Kijenzi/>}/>
                <Route path = '/academic_research' element = {<AcademicResearch/>}/>
                <Route path = '/academic_research/reliefbot' element = {<Reliefbot/>}/>
                <Route path = '/kijenzi/scoop' element = {<Scoop/>}/>
                <Route path = '/kijenzi/sec' element = {<Sec/>}/>
                {/* <Route path = '/resume' element = {<Resume/>}/> */}
                <Route path = '/kijenzi/cost_estimations' element = {<CostEstimation/>}/>
                <Route path = '/academic_research/thesis' element = {<Thesis/>}/>
                <Route path = '/kijenzi/erpnext' element = {<Erpnext/>}/>
                <Route path = '/from-energy' element ={<FormEnergy/>}/>
            </Routes>
        </div>
    )
}

//export default Switch