
import React, { useRef, Suspense, lazy} from 'react'
//import { useFrame } from '@react-three/fiber'
//import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
//import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useLoader } from '@react-three/fiber'
import { useGLTF, Edges, Stage, Bounds } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import secNew from './secNew.glb'
import {Canvas} from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { styleDefaults } from '../../../globals/globals.jsx'
import { createUseStyles } from 'react-jss'
import { types } from '../sec.jsx'

//const Model = lazy(() => import("./models/scoopModel.gltf"))

const position = [0,0,0]
//const rotation = [4.5,0,0]


const BlueFilter = (props) => {
    const group = useRef()
    const { nodes } = useGLTF(secNew)
    const { selected } = props
    const rotation = types.filter(x => x.name == selected)[0]['rotation']
    return (
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_0.geometry} position = {position} rotation = {rotation} material = {nodes.mesh_0.material}>
          <Edges/>
          <meshStandardMaterial
              attach="material"
              color="Blue"
              transparent
              //roughness={0.5}
              metalness={0.1}
          />
      </mesh>
  </group>
    )
}

const Lens = (props) => {
    const group = useRef()
    const { nodes } = useGLTF(secNew)
    const { selected } = props
    const rotation = types.filter(x => x.name == selected)[0]['rotation']
    return (
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_1.geometry} position = {position} rotation = {rotation} material = {nodes.mesh_1.material}>
          <Edges/>
          {/* <meshStandardMaterial
              attach="material"
              color=""
              roughness={0.5}
              metalness={0.5}
          /> */}
      </mesh>
  </group>
    )
}
const LensUnit = (props) => {
    const group = useRef()
    const { nodes, materials } = useGLTF(secNew)
    const { selected } = props
    const rotation = types.filter(x => x.name == selected)[0]['rotation']
    const color = types.filter(x => x.name == selected)[0]['lensUnitColor']
    return (
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_2.geometry} position={position} rotation = {rotation} material = {nodes.mesh_2.material}>
           <Edges/>
          <meshStandardMaterial
              attach="material"
              color={color}
              roughness={0.5}
              metalness={0.5}
          />
      </mesh>
  </group>
    )
}


const Lock = (props) => {
    const group = useRef()
    const { selected } = props
    const { nodes, materials } = useGLTF(secNew)
    const color = types.filter(x => x.name == selected)[0]['lockColor']
    const rotation = types.filter(x => x.name == selected)[0]['rotation']
    return (
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_3.geometry} position={position} rotation = {rotation} material = {nodes.mesh_3.material}>
           <Edges/>
          <meshStandardMaterial
              attach="material"
              color={color}
              roughness={0.5}
              metalness={0.5}
          />
      </mesh>
  </group>
    )
}

const Base = (props) => {
    const group = useRef()
    const { selected } = props
    const { nodes, materials } = useGLTF(secNew)
    const rotation = types.filter(x => x.name == selected)[0]['rotation']
    return (
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_4.geometry} position={position} rotation = {rotation} material = {nodes.mesh_4.material}>
           <Edges/>
          <meshStandardMaterial
              attach="material"
            //   color="#484848"
              color ='#484848'
              roughness={0.5}
              metalness={0.5}
          />
      </mesh>
  </group>
    )
}

const Mirror1 = (props) => {
    const group = useRef()
    const { nodes, materials } = useGLTF(secNew)
    const { selected } = props
    const rotation = types.filter(x => x.name == selected)[0]['rotation']
    return (
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_5.geometry} position={position} rotation = {rotation} material = {nodes.mesh_5.material}>
           <Edges/>
          <meshStandardMaterial
              attach="material"
              //color="Black"
              roughness={0.5}
              metalness={0.5}
          />
      </mesh>
  </group>
    )
}

const Rod = (props) => {
    const group = useRef()
    const { nodes, materials } = useGLTF(secNew)
    const { selected } = props
    const rotation = types.filter(x => x.name == selected)[0]['rotation']
    return (
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_6.geometry} position={position} rotation = {rotation} material = {nodes.mesh_6.material}>
           <Edges/>
          <meshStandardMaterial
              attach="material"
              //color="#484848"
              roughness={0.5}
              metalness={0.5}
          />
      </mesh>
  </group>
    )
}

const Cap1 = (props) => {
    const group = useRef()
    const { nodes, materials } = useGLTF(secNew)
    const { selected } = props
    const rotation = types.filter(x => x.name == selected)[0]['rotation']
    return (
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_7.geometry} position={position} rotation = {rotation} material = {nodes.mesh_7.material}>
           <Edges/>
          <meshStandardMaterial
              attach="material"
              color="#484848"
              roughness={0.5}
              metalness={0.5}
          />
      </mesh>
  </group>
    )
}

const Cap2 = (props) => {
    const group = useRef()
    const { nodes, materials } = useGLTF(secNew)
    const { selected } = props
    const rotation = types.filter(x => x.name == selected)[0]['rotation']
    return (
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_8.geometry} position={position} rotation = {rotation} material = {nodes.mesh_8.material}>
           <Edges/>
          <meshStandardMaterial
              attach="material"
              color="#484848"
              roughness={0.5}
              metalness={0.5}
          />
      </mesh>
  </group>
    )
}

const Mirror2 = (props) => {
    const group = useRef()
    const { nodes, materials } = useGLTF(secNew)
    const { selected } = props
    const rotation = types.filter(x => x.name == selected)[0]['rotation']
    return (
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_9.geometry} position={position} rotation = {rotation} material = {nodes.mesh_9.material}>
           <Edges/>
          <meshStandardMaterial
              attach="material"
              //color="#484848"
              roughness={0.5}
              metalness={0.5}
          />
      </mesh>
  </group>
    )
}

const SlitUnit = (props) => {
    const group = useRef()
    const { nodes, materials } = useGLTF(secNew)
    const { selected } = props
    const rotation = types.filter(x => x.name == selected)[0]['rotation']
    return (
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_10.geometry} position={position} rotation = {rotation} material = {nodes.mesh_10.material}>
           <Edges/>
          <meshStandardMaterial
              attach="material"
              color="#484848"
              roughness={0.5}
              metalness={0.1}
          />
      </mesh>
  </group>
    )
}
const Slit = (props) => {
    const group = useRef()
    const { nodes, materials } = useGLTF(secNew)
    const { selected } = props
    const rotation = types.filter(x => x.name == selected)[0]['rotation']
    return (
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_11.geometry} position={position} rotation = {rotation} material = {nodes.mesh_11.material}>
           <Edges/>
          <meshStandardMaterial
              attach="material"
              color="black"
              roughness={0.5}
              metalness={0.5}
          />
      </mesh>
  </group>
    )
}

const SecNew = (props) => {
    const styles = useStyles()
    const { selected } = props
    const target = types.filter(x => x.name == selected)[0]['target']
    //const scale = types.filter(x => x.name == selected)[0]['scale']
    return (
        <Canvas className = {styles.model} camera = {{ fov: 20}}>
            <Stage adjustCamera position= {[0,0,0]} contactShadow={{ resolution: 1024, scale: 20 }}>
                <BlueFilter selected={selected}/>
                <Lens selected={selected}/>
                <LensUnit selected={selected}/>
                <Base selected={selected}/>
                <Lock selected={selected}/>
                <Mirror1 selected={selected}/>
                <Mirror2 selected={selected}/>
                <Slit selected={selected}/>
                <Rod selected={selected}/>
                <Cap1 selected={selected}/>
                <Cap2 selected={selected}/>
                <SlitUnit selected={selected}/>
                <OrbitControls
                    enablePan={true}
                    enableZoom={true}
                    enableRotate={true}
                    autoRotate={true}
                    autoRotateSpeed={1}
                    //makeDefault ={[0,0,0]}
                    target ={target}
                    enableDamping={true}
                    dampingFactor={0.3}
                    />
            </Stage>
        </Canvas>
    )
}

const useStyles = createUseStyles({
     model: {
        width: '100vw',
        height: '200vh',
        margin: 'auto auto 10px auto',
        borderRadius: '2px',
        //marginBottom: '20px'
    }
})



export default SecNew