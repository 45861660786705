import React, { useRef, Suspense, lazy} from 'react'
//import { useFrame } from '@react-three/fiber'
//import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
//import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useLoader } from '@react-three/fiber'
import { useGLTF, Edges, Stage, Bounds } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import scoopModel from './scoopModelNew.glb'
import {Canvas} from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { styleDefaults } from '../../../globals/globals.jsx'

//const Model = lazy(() => import("./models/scoopModel.gltf"))

const position = [0,0,0]
const rotation = [4,0,-0.01]


const Stem = () => {
    const group = useRef()
    const { nodes } = useGLTF(scoopModel)
    // useFrame(() => {
    //     group.current.rotation.x += -0.01
    //     })
    return (
        //console.log('test')
        //console.log(nodes.mesh_0.geometry)
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_0.geometry} position = {position} rotation = {rotation} material = {nodes.mesh_0.material}>
          <Edges/>
          {/* <meshStandardMaterial
              attach="material"
              //color="darkgray"
              transparent
              roughness={0.5}
              metalness={0.5}
          /> */}
      </mesh>
  </group>
    )
}

const Scoop1 = () => {
    const group = useRef()
    const { nodes } = useGLTF(scoopModel)
    // useFrame(() => {
    // group.current.rotation.x += -0.01
    // })
    return (
        //console.log('test')
        //console.log(nodes.mesh_0.geometry)
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_1.geometry} position = {position} rotation = {rotation} material = {nodes.mesh_1.material}>
          <Edges/>
          <meshStandardMaterial
              attach="material"
              color="green"
              roughness={0.5}
              metalness={0.5}
          />
      </mesh>
  </group>
    )
}
const Scoop2 = () => {
    const group = useRef()
    const { nodes, materials } = useGLTF(scoopModel)
    // useFrame(() => {
    // group.current.rotation.x += -0.01
    // })
    return (
        //console.log('test')
        //console.log(nodes.mesh_0.geometry)
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_2.geometry} position={position} rotation = {rotation} material = {nodes.mesh_2.material}>
           <Edges/>
          <meshStandardMaterial
              attach="material"
              //color="green"
              color="#D78318"
              roughness={0.5}
              metalness={0.5}
          />
      </mesh>
  </group>
    )
}

const ScoopModelNew = () => {
    //console.log(scoopModel)
    //const group = useRef()
    //const obj = useLoader(OBJLoader, scoopModel)
    //const mesh = useRef()
    // useFrame(() => {
    // group.current.rotation.z += 0.01
    // })
    return (
        <Canvas camera = {{ fov:15 }}>
            <Stage adjustCamera position= {[0,0,0]}contactShadow={{ resolution: 1024, scale: 10 }} >
                {/* <ambientLight intensity = {0.00}/> */}
                {/* <spotLight 
                    intensity = {1}
                    position = {[-2,0,0]}
                    castShadow
                /> */}
                {/* <rectAreaLight
                    width = {3}
                    height = {3}
                    intensity = {1}
                    angle = {0.1}
                    color ='white'
                    penumbra = {1}
                    position = {[0,0,5]}
                    lookAt={[2.5,0,0]}
                    castShadow /> */}
                <Stem/>
                <Scoop1/>
                <Scoop2/>
                <OrbitControls
                    enablePan={true}
                    enableZoom={true}
                    enableRotate={true}
                    //makeDefault ={[0,0,0]}
                    target ={[0,1.25,0]}
                    //dampingFactor={0.3}
                    />
            </Stage>
        </Canvas>
    )
}



export default ScoopModelNew