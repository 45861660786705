import React, { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useGLTF, Edges} from '@react-three/drei'
import simon from '../assets/simon_scalled_down.gltf'

const Model = () => {
    const group = useRef()
    const { nodes } = useGLTF(simon)
    //const mesh = useRef()
    useFrame(() => {
    group.current.rotation.z += 0.01
    })

    return (
            <group ref={group} dispose={null} scale={0.0175}>
                <mesh geometry={nodes.mesh_0.geometry}>
                    <meshStandardMaterial
                        attach="material"
                        color="gray"
                        roughness={0.5}
                        metalness={0.1}
                    />
                </mesh>
            </group>
    )
}



export default Model