import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom'
import { Switch } from './routes.jsx'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Router>
    <Switch/>
  </Router>
)
