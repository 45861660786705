import { borderColor } from '@mui/system';
import { all } from 'rambda';
import {React, useState} from 'react'
import {createUseStyles} from 'react-jss'
import { styleDefaults } from '../globals/globals.jsx'



const Resume = () => {
    const styles = useStyles()
    return (
        <div>
            <div className = {styles.sectionHeading}>
                Education
            </div>
            <hr className = {styles.hrStyle}/>
            <div className = {styles.subSection}>
                <div className = {styles.boldLeft}>
                    The Pennsylvania State University
                </div>
                <div className = {styles.boldRight}>
                    September 2015 - May 2019
                </div>
                <br/>
                <div className = {styles.italicLeft}>
                    The Pennsylvania State University
                </div>
                <div className = {styles.italicRight}>
                    GPA 3.71
                </div>
            </div>
        </div>
    )
};

const style = styleDefaults()

const useStyles = createUseStyles({
    sectionHeading: {
        width: '60%',
        fontSize: style.headingFontSize,
        margin:'auto auto 10px auto',
        color: style.textColor,
        //color: color,
        textShadow: style.textShadow,
        fontWeight: 700,
        //textAlign: 'center',
        textDecoration: 'none'
    },
    descriptionText: {
        textDecoration: 'none',
        width: '50%',
        fontSize: '15pt',
        backgroundColor: style.backgroundColor,
        border: 'none',
        margin: 'auto auto 3vh auto',
        color: style.textColor,
        textAlign: 'center'
    },
    image: {
        //width: '50%',
        width: '50%',
        //minWidth: '50%',
        height: '30vh',
        margin: 'auto auto -1vh auto',
        border: 'solid',
        borderColor: style.borderColor,
        borderWidth: '2px',
        boxShadow: style.boxShadow,
        cursor:'pointer',
        //transform: 'scale(1,1)',
        transition: '0.5s',
        objectFit: 'cover',
        //objectPosition: '50% 10%'
    },
    title:{
        width: '80%',
        textAlign: 'center',
        fontSize: style.titleFontSize,
        margin: 'auto 10% 30px auto',
        fontWeight: '700',
        color: style.textColor
    },
    hrStyle:{
        width: '60%',
        margin: 'auto auto 20px auto'
    },
    subSection: {
        width:'60%',
        margin: 'auto auto auto auto'
    },
    boldLeft: {
        //width: '60%',
        fontSize: style.bodyFontSize,
        margin:'auto auto 10px auto',
        color: style.textColor,
        //color: color,
        textShadow: style.textShadow,
        fontWeight: 700,
        //textAlign: 'center',
        textDecoration: 'none',
        float: 'left'
    },
    boldRight: {
        //width: '60%',
        fontSize:  style.bodyFontSize,
        margin:'auto auto 10px auto',
        color: style.textColor,
        //color: color,
        textShadow: style.textShadow,
        fontWeight: 700,
        //textAlign: 'center',
        textDecoration: 'none',
        textAlign: 'right',
        float: 'right'
    },
        italicLeft: {
        //width: '60%',
        fontSize: style.bodyFontSize,
        margin:'auto auto 10px auto',
        color: style.textColor,
        //color: color,
        textShadow: style.textShadow,
        //textAlign: 'center',
        textDecoration: 'none',
        float: 'left',
        fontStyle: 'italic'
    },
    italicRight: {
        //width: '60%',
        fontSize:  style.bodyFontSize,
        margin:'auto auto 10px auto',
        color: style.textColor,
        //color: color,
        textShadow: style.textShadow,
        //textAlign: 'center',
        textDecoration: 'none',
        textAlign: 'right',
        float: 'right',
        fontStyle: 'italic'

    },

});

export default Resume