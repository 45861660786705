import React, { useRef, Suspense, lazy} from 'react'
//import { useFrame } from '@react-three/fiber'
//import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
//import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useLoader } from '@react-three/fiber'
import { useGLTF, Edges, Stage, Bounds } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import scoopModel from './scoopModelOld.glb'
import {Canvas} from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { styleDefaults } from '../../../globals/globals.jsx'

//const Model = lazy(() => import("./models/scoopModel.gltf"))

const position = [0,0,0]
const rotation = [-0.7,0.03,1.625]

const Scoop = () => {
    const group = useRef()
    const { nodes, materials } = useGLTF(scoopModel)
    // useFrame(() => {
    // group.current.rotation.x += -0.01
    // })
    return (
        //console.log('test')
        //console.log(nodes.mesh_0.geometry)
      <group ref={group} dispose={null}>
      <mesh geometry={nodes.mesh_0.geometry} position={position} rotation = {rotation} material = {nodes.mesh_0.material}>
           <Edges/>
          <meshStandardMaterial
              attach="material"
              //color="green"
              color="green"
              roughness={0.5}
              metalness={0.5}
          />
      </mesh>
  </group>
    )
}

const ScoopModelOld = () => {
    //console.log(scoopModel)
    //const group = useRef()
    //const obj = useLoader(OBJLoader, scoopModel)
    //const mesh = useRef()
    // useFrame(() => {
    // group.current.rotation.z += 0.01
    // })
    return (
        <Canvas camera = {{ fov:15 }}>
            <Stage adjustCamera position= {[0,0,0]}contactShadow={{ resolution: 1024, scale: 10 }} >
                <Scoop/>
                <OrbitControls
                    enablePan={true}
                    enableZoom={true}
                    enableRotate={true}
                    //makeDefault ={[0,0,0]}
                    target ={[0,1.5,0]}
                    />
            </Stage>
        </Canvas>
    )
}



export default ScoopModelOld