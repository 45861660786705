import { borderColor } from '@mui/system';
import { all } from 'rambda';
import {React, useState} from 'react'
import {createUseStyles} from 'react-jss'
import reliefBotFolded from './images/ReliefBotFolded.jpg'
import thesisBeadFull from './images/thesisBeadFull.png'
import { styleDefaults } from '../globals/globals.jsx'



const AcademicResearch = () => {
    const styles = useStyles()
    const [state, setState] = useState({
        engineeringDesignList: engineeringDesignList
    })
    return (
        <div>
            <div className={styles.sectionText}>
                <div>
                    {state.engineeringDesignList.map((linkData,i) => {
                        const { text, link, image, hover } = linkData
                        return (
                            <a key={image} href={link}
                                style={{marginRight: (i % 2 === 0 && i !== 0)}}
                            onMouseEnter = {() =>  setState((s) => 
                                ({...s, engineeringDesignList: s.engineeringDesignList.map(x => ({ ...x, hover:x.link == link}))}))}
                            onMouseLeave = {() => setState((s) =>
                                ({...s, engineeringDesignList: s.engineeringDesignList.map(x => ({ ...x, hover: false}))}))}
                            >
                                <img className={styles.image} src={image} alt={''}  style = {{transform: linkData.hover ? 'scale(1.01)': 'scale(1)'}} />
                                <div>
                                    <input type='text' value = {text} className = {styles.descriptionText}/>
                                </div>
                            </a>
                        )
                    })}
                </div>
            </div>
        </div>
    )
};

const Link = (text, link, image, hover) => {
    return {text, link, image, hover}
}
const engineeringDesignList = [
    Link('Undergraduate Thesis - Effects of Boron Nitride Nanotube Reinforcement on the Mechanical Properties of Ti-6Al-4V','/academic_research/thesis', thesisBeadFull),
    Link('Design of Foldable 3D Printer For Disaster Relief','/academic_research/reliefbot', reliefBotFolded),

]

const style = styleDefaults()

const useStyles = createUseStyles({
    sectionText: {
        width: '95%',
        maxWidth: '1100px',
        fontSize: style.headingFontSize,
        margin:'2% auto 30px auto',
        color: style.textColor,
        textShadow: style.textShadow,
        fontWeight: 700,
        //textAlign: 'center',
        textDecoration: 'none'
    },
    descriptionText: {
        textDecoration: 'none',
        width: '100%',
        fontSize: style.bodyFontSize,
        backgroundColor: style.backgroundColor,
        border: 'none',
        margin: 'auto auto 3vh auto',
        color: style.textColor,
        textAlign: 'center',
        font : 'Garamond'
    },
    image: {
        width: '100%',
        //minWidth: '50%',
        height: 'calc(1100px*.3708)',
        maxHeight: 'calc(95vw*.3708)',
        margin: '10px auto auto auto',
        //border: 'solid',
        borderColor: style.borderColor,
        borderWidth: '2px',
        boxShadow: style.boxShadow,
        cursor:'pointer',
        //transform: 'scale(1,1)',
        transition: '0.5s',
        objectFit: 'cover',
        objectPosition: '50% 5%'
    },
    title:{
        width: '100%',
        textAlign: 'center',
        fontSize: style.titleFontSize,
        margin: 'auto 10% 30px auto'
    }

});

export default AcademicResearch