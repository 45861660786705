import {React} from 'react'
import { createUseStyles } from 'react-jss'
import { styleDefaults } from '../../globals/globals.jsx'
import parts from './images/parts.png'
import costGraph from '../images/costEstimation.png'
import costSize from './images/costSize.png'

const CostEstimation = () => {
    const styles = useStyles()
    return(
        <div className = {styles.body}>
            <div className = {styles.title}>
                Engineering and Manufacturing Cost Estimation
            </div>
            <img className  = {styles.imageFlat} src = {parts}/>
            <hr className = {styles.hrStyle} />
            <div className= {styles.sectionHeading}>
                BACKGROUND
            </div>
            <div className = {styles.sectionBody}>
                Kijenzi makes parts of every shape and size, using a variety of materials for many different applications.
                In order to provide customers with a quotation, the costs of designing and manufacturing a product have to be first be estimated.
                These estimations have to be accurate and consistent from engineer to engineer. I was tasked with developing this standardized estimation process. 
            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                UNDERSTANDING COST
            </div>
            <div className = {styles.sectionBody}> 
                Before cost can be estimated, I first had to properly understand the engineering and manufacturing costs.
                I created a costing model to encompass the costs of the entire company including overhead, material, printer ROI and more. 
                I then created formulas to calculate these costs of manufacturing a given part based on the model.
            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                PRODUCT SCREENING
            </div>
            <div className = {styles.sectionBody2}>
                I created a standardized screening that could be performed by any engineer to quickly and consistently estimate the cost of a product before it was designed.
                To Accomplish this I analyzed existing products and determined the factors that contributed to the manufacturing and engineering costs the most and created an equation to create an estimation.
                The sales team were then able to take the cost estimate and appropriately price the product depending on existing market.
                
                {/* First the products were separated into three groups: catalog products, products Kijenzi has made before and already has the digital design for, custom products, products that we received a design from the customer, and digital design products, products that are a concept or part that we do not have the digital design for.
                I gave catalog products a set price depending on the calculated costs and market information from the sales team.
                For custom products, the product was analyzed for manufacturing materials and time then costed based on the estimations the sales team then decided an appropriate price.
                For design products it was much more complicated as the costs needed to be estimated without a design to accurately asses the needed materials or time. */}
            </div>
            {/* <div className = {styles.sectionBody2}>
                To accomplish this estimation for design products, I devised a screening to quickly and consistently cost and quote incoming orders. The screening first assessed 3 properties on a sliding 1-5 scale.
                Design Complexity, an evaluation of the complexity of the geometries that make up the design. Iteration number, an estimation of the difficulty of achieving the product requirements for example a part requiring many tight fittings or slides would score high on the scale, and a part that was more astatic would score low.
                And lastly replication number, how closely a product is a copy of a reference part vs. a design completely from description without reference. These three factors were used to calculate the estimated design time for the product. 
            </div>
            <div className = {styles.sectionBody}>
                To estimate the manufacturing cost the product, I used additional variables such as X, Y, and Z dimensions, manufacturing machine, material type, and a strength modifier, a value on a sliding scale of 1-5 estimating the load requirements of the part.
                The additional factors above were also considered for the estimation equation but I determined their corelation to the final manufacturing cost to be minimal. 
                Analysis was performed on sample parts to derive an equation to estimate the cost of a part based on these factors.
            </div> */}
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                IMPLEMENTATION AND EVALUATION
            </div>
            <img className  = {styles.imageFlat} src = {costGraph}/>
            <div className = {styles.sectionBody}>
                The screening and estimation methods were implemented at Kijenzi to screen incoming products and provide quotations to customers.
                I analyzed the estimations by comparing them to the calculated costs after the projects completion.
                I found that generally the estimation was within 50% of the calculated costs. I further adjusted the estimation equation based on the additional findings to improve it further. 
            </div>
            <img className  = {styles.imageFlat} src = {costSize}/>
        </div>
    )
}

const style = styleDefaults()

const useStyles = createUseStyles({
    mainImage: {
        width: '50%',
        margin: 'auto auto 20px auto',
        //border: 'solid',
        //borderColor: style.borderColor,
        boxShadow: style.boxShadow
    },
    imageFlat: {
        width: '60%',
        //maxWidth: '800px',
        margin: 'auto auto 20px 20%',
        //border: 'solid',
        //borderColor: style.borderColor,
        boxShadow: style.boxShadow
    },
    sectionHeading: {
        width: '100%',
        fontSize: style.headingFontSize,
        margin:'auto auto 10px auto',
        color: style.textColor,
        //color: color,
        textShadow: style.textShadow,
        fontWeight: '700',
        //textAlign: 'center',
        textDecoration: 'none'
    },
    sectionBody:{
        width: '100%',
        margin: 'auto auto 20px auto',
        fontSize: style.bodyFontSize,
        color: style.textColor,
        textShadow: style.textShadow,
        textAlign: 'justify',
        //backgroundColor: style.accentColor2,
    },
    sectionBody2:{
        width: '100%',
        margin: 'auto auto 10px auto',
        fontSize: style.bodyFontSize,
        color: style.textColor,
        textShadow: style.textShadow,
        textAlign: 'justify',
        //backgroundColor: style.accentColor2,
    },
    hrStyle:{
        width: '100%',
        margin: 'auto auto 10px auto',
        backgroundColor: style.accentColor,
        height: '1pt',
        border: 'none',
        borderColor: style.accentColor
    },
    title:{
        width: '100%',
        textAlign: 'center',
        fontSize: style.titleFontSize,
        margin: 'auto auto 20px auto',
        fontWeight: '700',
        color: style.textColor
    },
    body:{
        width: '95%',
        maxWidth: '1100px',
        //textAlign: 'justify',
        margin: 'auto auto auto auto'
    },
        modelNotification: {
        textAlign: 'right',
        fontSize: style.bodyFontSize,
        textShadow: style.textShadow,
        color: style.textColor,
    }
})


export default CostEstimation