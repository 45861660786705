import {React} from 'react'
import { createUseStyles } from 'react-jss'
import { styleDefaults } from '../../globals/globals.jsx'
import stageIsoView from './images/stageIsoView.png'
import fullBeads from './images/fullBeads.png'
import fullSide from './images/fullSide.png'
import hardness from './images/hardness.png'
import hardnessGraph from './images/hardnessGraph.png'
import fracture from '../images/thesisBeadFull.png'
import fullThesis from './Lipsky-Thesis.pdf'

const Thesis = () => {
    const styles = useStyles()
    return(
        <div className = {styles.body}>
            <div className = {styles.title}>
                UNDERGRADUATE THESIS - EFFECTS OF BORON NITRIDE NANOTUBE REINFORCEMENT ON THE MECHANICAL PROPERTIES OF TI-6AL-4V
            </div>
            {/* <img className  = {styles.mainImage} src = {frontView}/> */}
            <hr className = {styles.hrStyle} />
            <div className= {styles.sectionHeading}>
                BACKGROUND
            </div>
            <div className = {styles.sectionBody}>
                Ti-6Al-4V is a titanium alloy that is commonly used in additive manufacturing processes due to it's high strength to weight ratio, high corrosion resistance, and high temperature mechanical properties.
                Unfortunately one of the drawbacks of the alloy is it's relatively low wear resistance.
                Reinforcement of Ti-6Al-4V with Boron Nitride Nano Tubes (BNNT) could offer the ability to increase the hardness and strength of the material without compromising the ductility or toughness.
                This would increase the wear resistance of Ti-6Al-4V as wear resistance is directly proportional to a material's hardness and toughness.
            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                SCOPE
            </div>
            <div className = {styles.sectionBody}>
                Within this thesis I conducted an extensive literature review of existing research nanotube reinforced of different metals, designed an experiment, manufactured samples, and tested the mechanical properties.
                I manufactured the samples made of BNNT reinforced Ti-6Al-4V using directed energy deposition additive manufacturing.
                I varied BNNT weight percentage, hatch spacing, and laser power.
                The goal was to try and retain the BNNT in the metal matrix to determine if the theoretical advantages could be achieved. 
                I measured the samples' hardness and used SEM imaging to analyze the fracture surfaces to observe ductility this allowed me to determine if the BNNTs were retained.
            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                DESIGN OF EXPERIMENT
            </div>
            <img className  = {styles.mainImage} src = {stageIsoView}/>
            <div className = {styles.sectionBody}>
                Due to the low supply of BNNT, I designed a linier stage to allow for a more controlled use of the raw material.
                This stage emulated the process of a powder bed fusion machine, where I rolled a thin layer of powder over stage, the laser melted the material together, then I lowered the stage to repeat the process.
                I then conducted 8 bead on plate depositions, to determine suitable processing parameters for full depositions.
            </div>
            <img className  = {styles.mainImage} src = {fullBeads}/>
            <div className = {styles.sectionBody}>
                To try and retain the BNNT, minimal laser power and on duration were determined. If the heat from the laser was to high the BNNT would be destroyed.
                After these ideal parameters were determined I performed single layer deposition to understand the ideal hatch spacing.
                Finally I performed multi-layer depositions using the parameters determined in the previous experiments. 
            </div>
            <img className  = {styles.imageFlat} src = {fullSide}/>
            <hr className={styles.hrStyle}/>
            <div className = {styles. sectionHeading}>
                RESULTS
            </div>
            <img className  = {styles.mainImage} src = {hardness}/>
            <div className = {styles.sectionBody}>
                I measured the Vickers hardness of the deposited beads above and bellow the substrate, I observed the hardness was substantially higher than below the substrate for all the depositions containing BNNT. 
            </div>
            <img className  = {styles.mainImage} src = {hardnessGraph}/>
            <div className = {styles.sectionBody}>
                I then fractured the surface of the depositions and analyzed the surface using SEM imaging to determine if the fracture was brittle or ductile.
                The image bellow shows a brittle fracture of the BNNT samples. This fracture mode was observed in all BNNT samples while a ductile fracture was observed in the pure Ti-6Al-4V samples.
            </div>
            <img className  = {styles.mainImage} src = {fracture}/>
            <div className = {styles.sectionBody}>
                Lastly I used X-ray diffraction to analyze the phase structure of the sample. Both titanium alpha phase and TiN were observed in the deposition.
            </div>
            <hr className={styles.hrStyle}/>
            <div className = {styles. sectionHeading}>
                CONCLUSION
            </div>
            <div className = {styles.sectionBody}>
                Due to the fracture and X-ray diffraction data I determined that the BNNTs were not likely retained.
                The increase in hardness can be attributed to the TiN and alpha phases from the break down of the BNNT.
                Additionally the low heat of deposition left a lot of porosity in the sample meaning reducing laser power would likely not produce usable samples.
                This suggested that retaining BNNT within the metal matrix may prove difficult. 
            </div>
            <hr className={styles.hrStyle}/>
            <div className = {styles. sectionHeading}>
                FULL REPORT
            </div>
            <div className = {styles.sectionBody}>
                My full thesis can be found <a target='_blank' href={fullThesis} style = {{color:style.linkColor}} >here</a>
            </div>
        </div>
    )
}

const style = styleDefaults()

const useStyles = createUseStyles({
    mainImage: {
        width: '50%',
        margin: 'auto auto 20px 25%',
        //border: 'solid',
        //borderColor: style.borderColor,
        boxShadow: style.boxShadow
    },
    imageFlat: {
        width: '90%',
        margin: 'auto auto 20px 5%',
        //border: 'solid',
        //borderColor: style.borderColor,
        boxShadow: style.boxShadow
    },
    sectionHeading: {
        width: '100%',
        fontSize: style.headingFontSize,
        margin:'auto auto 10px auto',
        color: style.textColor,
        //color: color,
        textShadow: style.textShadow,
        fontWeight: '700',
        //textAlign: 'center',
        textDecoration: 'none'
    },
    sectionBody:{
        width: '100%',
        margin: 'auto auto 20px auto',
        fontSize: style.bodyFontSize,
        color: style.textColor,
        textShadow: style.textShadow,
        textAlign: 'justify',
        //backgroundColor: style.accentColor2,
    },
    hrStyle:{
        width: '100%',
        margin: 'auto auto 10px auto',
        backgroundColor: style.accentColor,
        height: '1pt',
        border: 'none',
        borderColor: style.accentColor
    },
    title:{
        width: '100%',
        textAlign: 'center',
        fontSize: style.titleFontSize,
        margin: 'auto auto 20px auto',
        fontWeight: '700',
        color: style.textColor
    },
    body:{
        width: '95%',
        maxWidth: '1100px',
        //textAlign: 'center',
        margin: 'auto auto auto auto'
    }
})


export default Thesis