import {React} from 'react'
import { createUseStyles } from 'react-jss'
import { styleDefaults } from '../../globals/globals.jsx'
import {Canvas} from '@react-three/fiber'
import ScoopModelNew from './models/scoopModelNew.jsx'
import ScoopModelOld from './models/scoopModelOld.jsx'
import { OrbitControls } from '@react-three/drei'

const Scoop = () => {
    const styles = useStyles()
    return(
        <div className = {styles.body}>
            <div className = {styles.title}>
                FERTILIZER SCOOP DESIGN FOR MANUFACTURING
            </div>
            {/* <img className  = {styles.mainImage} src = {frontView}/> */}
            <hr className = {styles.hrStyle} />
            <div className= {styles.sectionHeading}>
                BACKGROUND
            </div>
            <div className = {styles.sectionBody}>
            <a target="_blank" href='https://oneacrefund.org/' style = {{color:style.linkColor}}>One Acre Fund</a> (OAF), an East African non-profit who services smallholder farmers in East Africa, was in need
            of a fertilizer scoop with very precise measurements to help farmers measure out fertilizer for their crops. They requested 5000 scoops be manufactured by Kijenzi in Kenya. They required varying scoop sizes on either end for testing.
            With the volumes and variation required, the order provided a perfect application for manufacturing using 3D printing and laser cutting.
            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                REQUIREMENTS
            </div>
            <div className = {styles.sectionBody}> 
                One acre fund provided the original model shown below, they required modifications for different scoop amounts, additionally the scoops had to be multi-colored to distinguish between the different cup volumes.
            </div>
            <div className={styles.modelNotification}>
                Click and drag the model to interact
            </div>
            <div className = {styles.model}>
                <ScoopModelOld/>
            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                DESIGN MODIFICATIONS
            </div>
            <div className = {styles.sectionBody}>
                While the original design was sufficient for the testing required by OAF the design had several drawbacks: A completely different part needed to be manufactured to change one of the scoop volumes, and the part required painting to meet the color specifications.
                These drawbacks increased manufacturing cost, and with the expectation of more variations of the scoop being needed in the future, I modified the design.
            </div>
            <div className={styles.modelNotification}>
                Click and drag the model to interact
            </div>
            <div  className = {styles.model}>
                <ScoopModelNew/>
            </div>
            <div className = {styles.sectionBody}>
                I designed the scoop to be a 3-part assembly consisting of two 3D printed scoops that fit press into a laser cut connecting section. These modifications allowed for the center connecting
                piece to be consistent and not require modification for different scoop sizes. Additionally laser cutting the piece decreased manufacturing time and allowed for the product logo to be etched into the piece.

            </div>
        </div>
    )
}

const style = styleDefaults()

const useStyles = createUseStyles({
    mainImage: {
        width: '50%',
        margin: 'auto auto 20px 25%',
        //border: 'solid',
        //borderColor: style.borderColor,
        boxShadow: style.boxShadow
    },
    imageFlat: {
        width: '90%',
        margin: 'auto auto 20px 5%',
        //border: 'solid',
        //borderColor: style.borderColor,
        boxShadow: style.boxShadow
    },
    sectionHeading: {
        width: '100%',
        fontSize: style.headingFontSize,
        margin:'auto auto 10px auto',
        color: style.textColor,
        //color: color,
        textShadow: style.textShadow,
        fontWeight: '700',
        //textAlign: 'center',
        textDecoration: 'none'
    },
    sectionBody:{
        width: '100%',
        margin: 'auto auto 20px auto',
        fontSize: style.bodyFontSize,
        color: style.textColor,
        textShadow: style.textShadow,
        textAlign: 'justify',
        //backgroundColor: style.accentColor2,
    },
    hrStyle:{
        width: '100%',
        margin: 'auto auto 10px auto',
        backgroundColor: style.accentColor,
        height: '1pt',
        border: 'none',
        borderColor: style.accentColor
    },
    title:{
        width: '100%',
        textAlign: 'center',
        fontSize: style.titleFontSize,
        margin: 'auto auto 20px auto',
        fontWeight: '700',
        color: style.textColor
    },
    body:{
        width: '95%',
        maxWidth: '1100px',
        //textAlign: 'center',
        margin: 'auto auto auto auto'
    },
        modelNotification: {
        textAlign: 'right',
        fontSize: style.bodyFontSize,
        textShadow: style.textShadow,
        color: style.textColor2,
        fontWeight: '700'
    },
    model: {
    width: '95vw',
    maxWidth: '1100px'
    }
})


export default Scoop