import React from "react";
import {createUseStyles} from 'react-jss'
import { R } from  '../globals/globals.jsx'
import { styleDefaults } from '../globals/globals.jsx'
import s from '../s.svg'
import resume from '../Resume/Lipsky-Resume.pdf'

const Navbar = ({history}) => {
    const styles = useStyles()
    const urlPath = R.path(['location','pathname'], history);
 //   const isHome = urlPath.includes('home') || R.equals(urlPath, '/')
    return (
        <div className={styles.navbar}>
            <a className={styles.navbarLogo} target = '' href = {'/home'}>
               <img src={s}/>
            </a>
            <a className = {styles.leftNavbarButton} target = '' href = {'/form-energy'}>
                FORM ENERGY
            </a>
            <a className = {styles.navbarButton} target = '' href = {'/kijenzi'}>
                KIJENZI
            </a>
            <a className = {styles.navbarButton} target = '' href = {'/academic_research'}>
                ACADEMIC RESEARCH
            </a>
            <a className = {styles.rightNavbarButton} target = '_blank' href = {resume}>
                RESUME
            </a> 
        </div>
    )
}

const style = styleDefaults()

const useStyles = createUseStyles ({
    navbar: {
        width: '95%',
        maxWidth: '1100px',
        margin: 'auto auto auto auto',
        display: 'flex',
        height: '15vh',
        backgroundColor: style.backgroundColor
        //boxShadow: '0 10px 15px rgba(0,0,0,0.2)',
    },
    navbarLogo: {
        //fontSize: style.titleFontSize,
        marginTop: 'auto',
        marginBottom: 'auto',
        textDecoration: 'none',
        textAlign: 'left',
        cursor:'pointer',
        fontWeight: '500',
        color: style.textColor,
        textShadow: style.textShadow,
    },
    navbarButton: {
        fontSize: style.bodyFontSize,
        width: 'auto',
        margin: 'auto 10px auto 10px',
        textAlign: 'right',
        //flex: '1',
        textDecoration: 'none',
        color: style.textColor,
        textShadow: style.textShadow,
    }, 
    rightNavbarButton: {
        fontSize: style.bodyFontSize,
        width: 'auto',
        margin: 'auto 0px auto 10px',
        textAlign: 'left',
        textDecoration: 'none',
        color: style.textColor,
        textShadow: style.textShadow,
    },
    leftNavbarButton: {
        fontSize: style.bodyFontSize,
        width: 'auto',
        margin: 'auto 10px auto auto',
        textAlign: 'right',
        textDecoration: 'none',
        color: style.textColor,
        textShadow: style.textShadow,
    }


})

export default Navbar