import {React} from 'react'
import { createUseStyles } from 'react-jss'
import erpnextImage from './images/erpnext.png'
import { styleDefaults } from '../../globals/globals.jsx'
import manufacturingFlow from './images/manufacturingFlow.png'

const Erpnext = () => {
    const styles = useStyles()
    return(
        <div className = {styles.body}>
            <div className = {styles.title}>
            ERP CUSTOMIZATION AND IMPLEMENTATION
            </div>
            <img className  = {styles.imageFlat} src = {erpnextImage}/>
            <hr className = {styles.hrStyle} />
            <div className= {styles.sectionHeading}>
                BACKGROUND
            </div>
            <div className = {styles.sectionBody}>
                Kijenzi's goal is to have distributed manufacturing network across sub-saharan africa.
                To accomplish this a platform is needed to gather and maintain data, maintain consistent quality, and ensure overall company organization. 
                I took this platform though multiple iterations culminating in the customization and implementation of an Enterprise Resource Planning (ERP) system used to manage all of Kijenzi's operations.
            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                ERPNext
            </div>
            <div className = {styles.sectionBody}> 
                <a target="_blank" href='https://erpnext.com' style = {{color:style.linkColor}}>ERPNext</a> is an open source ERP software build on the a python framework called Frappe.
                I chose to use ERPNext due to it being open source, easily customizable, and well functioning.
                ERPNext has great documentation and a large community making it the perfect choice for use at Kijenzi. 

            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                Customization
            </div>
            {/* <img className  = {styles.mainImage} src = {motorAssembly}/> */}
            <div className = {styles.sectionBody}>
                To customize ERPNext to fit Kijenzi's needs I had to first gain a good understanding of ERPNext, the Frappe framework, and its capabilities.
                I then had to analyze all of Kijenzi's operational workflows and merge the two.
                This meant both customizing the ERPNext software to include the functionality needed for workflows, and altering the workflows themselves to better fit the bounds of the software.
                To accomplish this I hired, trained, and managed two junior software developers, managed the codebase for the customizations, and organized documentation.
            </div>
            <img className  = {styles.mainImage} src = {manufacturingFlow}/>
            <div className = {styles.sectionBody}>
                For example when using 3D-Printing FFF manufacturing jobs often fail mid-print. When this happens not all the material reserved for the job are used.
                The unused material needs to be recorded so the stock records stay up to date. ERPNext did not have this functionality so this ability needed to be implemented into the software.
                The flowchart above shows the workflow of the customization. I created this and similar process analysis for all departments of Kijenzi including sales, engineering, accounting, HR, customer relations, and more. 
            </div>
            <hr className={styles.hrStyle}/>
            <div className = {styles. sectionHeading}>
                Implantation
            </div>
            {/* <img className  = {styles.mainImage} src = {latch}/> */}
            <div className = {styles.sectionBody}>
                After I customized the software, I had to implement it throughout the company. To do this I had to host the software, generate user documentation, train the employees on their individual responsibilities within the software, and maintain the entirety of the software while continuously pushing the development forward.
                The software is currently in use by Kijenzi and it has allowed us to keep records leading to greater learning and helping with ISO 9001 compliance.
            </div>
            {/* <img className  = {styles.imageFlat} src = {folded}/> */}
        </div>
    )
}

const style = styleDefaults()

const useStyles = createUseStyles({
    mainImage: {
        width: '50%',
        margin: 'auto auto 20px 25%',
        //border: 'solid',
        //borderColor: style.borderColor,
        boxShadow: style.boxShadow
    },
    imageFlat: {
        width: '90%',
        margin: 'auto auto 20px 5%',
        //border: 'solid',
        //borderColor: style.borderColor,
        boxShadow: style.boxShadow
    },
    sectionHeading: {
        width: '100%',
        fontSize: style.headingFontSize,
        margin:'auto auto 10px auto',
        color: style.textColor,
        //color: color,
        textShadow: style.textShadow,
        fontWeight: '700',
        //textAlign: 'center',
        textDecoration: 'none'
    },
    sectionBody:{
        width: '100%',
        margin: 'auto auto 20px auto',
        fontSize: style.bodyFontSize,
        color: style.textColor,
        textShadow: style.textShadow,
        textAlign: 'justify',
        //backgroundColor: style.accentColor2,
    },
    hrStyle:{
        width: '100%',
        margin: 'auto auto 10px auto',
        backgroundColor: style.accentColor,
        height: '1pt',
        border: 'none',
        borderColor: style.accentColor
    },
    title:{
        width: '100%',
        textAlign: 'center',
        fontSize: style.titleFontSize,
        margin: 'auto auto 20px auto',
        fontWeight: '700',
        color: style.textColor
    },
    body:{
        width: '95%',
        maxWidth: '1100px',
        //textAlign: 'center',
        margin: 'auto auto auto auto'
    }
})


export default Erpnext