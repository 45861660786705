import {React} from 'react'
import { createUseStyles } from 'react-jss'
import frontView from './images/frontView.jpg'
import motorAssembly from './images/motorAssembly.jpg'
import motorAssembly2 from './images/motorAssembly2.jpg'
import folded from '../images/ReliefBotFolded.jpg'
import latch from './images/latch.jpg'
import { styleDefaults } from '../../globals/globals.jsx'

const Reliefbot = () => {
    const styles = useStyles()
    return(
        <div className = {styles.body}>
            <div className = {styles.title}>
                DESIGN OF FOLDABLE 3D PRINTER FOR DESASTER RELIEF
            </div>
            <img className  = {styles.mainImage} src = {frontView}/>
            <hr className = {styles.hrStyle} />
            <div className= {styles.sectionHeading}>
                BACKGROUND
            </div>
            <div className = {styles.sectionBody}>
                The 72-hour time period immediately following a natural disaster 
                is when the affected people are in the most need of relief aid.
                During this time period, relief aid organizations suffer from bottle necked and expensive supply chains that
                are required to transport items to a post disaster area. In addition to these complexities, communication about what supplies
                are needed in the affected areas is often minimal or completely cut off. Therefore, relief organizations must rely on estimates
                to decide on what supplies are brought with and the quantity of said supplies. There is a need for a way to provide those affected
                by disaster with exactly the supplies needed to increase the speed and effectiveness of the relief aid supply chain. Fused Filament Fabrication (FFF)
                is common type of 3D printing technology that demonstrates the capabilities needed to help solve the problems in the relief aid supply chain.
            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                GOAL
            </div>
            <div className = {styles.sectionBody}> 
                The goal of this project was to design a 3D printer specifically for the goal of disaster relief. The requirements were obtained from the non profit <a target="_blank" href='https://fieldready.org' style = {{color:style.linkColor}}>Field Ready</a>,
                who uses FFF for disaster relief. The requirements were determined to fit into 3 catagories, durability, portability, and ability to print off-grid.
            </div>
            <div className = {styles.sectionBody}>
                Results were presented at the 2019 IEEE Global Humanitarian Technology Conference (GHTC). The full publication can be found <a target='_blank' href='https://ieeexplore.ieee.org/abstract/document/9033053' style = {{color:style.linkColor}}>here</a>

            </div>
            <hr className = {styles.hrStyle} />
            <div className = {styles.sectionHeading}>
                DESIGN - MOTOR ASSEMBLY
            </div>
            <img className  = {styles.mainImage} src = {motorAssembly}/>
            <div className = {styles.sectionBody}>
                I chose a cartesian core-XZ design for the printer. This technique uses two motors attached with a pulley system to control both the X and Z axises.
                I chose tThis design to provide a compact motor assembly that could easily be protected to increase durability.
            </div>
            <img className  = {styles.mainImage} src = {motorAssembly2}/>
            <hr className={styles.hrStyle}/>
            <div className = {styles. sectionHeading}>
                DESIGN - FOLDABILITY
            </div>
            <img className  = {styles.mainImage} src = {latch}/>
            <div className = {styles.sectionBody}>
                To improve the portability of the printer, I designed it to fold flat allowing it to easily be packed in a case for transportation.
                Latches and hindges were placed on either side of the printer allowing to printer to either lock upright or fold flat.  
            </div>
            <img className  = {styles.imageFlat} src = {folded}/>
        </div>
    )
}

const style = styleDefaults()

const useStyles = createUseStyles({
    mainImage: {
        width: '50%',
        margin: 'auto auto 20px 25%',
        //border: 'solid',
        //borderColor: style.borderColor,
        boxShadow: style.boxShadow
    },
    imageFlat: {
        width: '90%',
        margin: 'auto auto 20px 5%',
        //border: 'solid',
        //borderColor: style.borderColor,
        boxShadow: style.boxShadow
    },
    sectionHeading: {
        width: '100%',
        fontSize: style.headingFontSize,
        margin:'auto auto 10px auto',
        color: style.textColor,
        //color: color,
        textShadow: style.textShadow,
        fontWeight: '700',
        //textAlign: 'center',
        textDecoration: 'none'
    },
    sectionBody:{
        width: '100%',
        margin: 'auto auto 20px auto',
        fontSize: style.bodyFontSize,
        color: style.textColor,
        textShadow: style.textShadow,
        textAlign: 'justify',
        //backgroundColor: style.accentColor2,
    },
    hrStyle:{
        width: '100%',
        margin: 'auto auto 10px auto',
        backgroundColor: style.accentColor,
        height: '1pt',
        border: 'none',
        borderColor: style.accentColor
    },
    title:{
        width: '100%',
        textAlign: 'center',
        fontSize: style.titleFontSize,
        margin: 'auto auto 20px auto',
        fontWeight: '700',
        color: style.textColor
    },
    body:{
        width: '95%',
        maxWidth: '1100px',
        //textAlign: 'center',
        margin: 'auto auto auto auto'
    }
})


export default Reliefbot