export const R = require('rambda')


export const styleDefaults = () => {
    return {    
        backgroundColor: '#222222',
        textColor: '#E5D8FF',
        textColor2: '#E5D8FF',
        accentColor: '#E5D8FF',
        accentColor2: '#E5D8FF',
        linkColor: '#8AB4F8',
        textShadow: '2px 2px 10px #000',
        boxShadow: '0 2px 10px #000',
        borderColor: '#CAEBF2',
        headingFontSize: '1.25em',
        bodyFontSize: '1em',
        titleFontSize: '1.5em',
        heading2FontSize: '1.125em'
    }
}