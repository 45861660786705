import { borderColor } from '@mui/system';
import { all } from 'rambda';
import {React, useState, useEffect} from 'react'
import {createUseStyles} from 'react-jss'
import scoop from './images/Urea Scoop.jpg'
import sec from './images/secFull.jpg'
import costImage from './images/costEstimation.png'
import { styleDefaults } from '../globals/globals.jsx'
import kijenziLogo from './images/kijenzi-logo-master-white.png'
import erpnext from './images/erpnext.jpg'



const Kijenzi = () => {
    const styles = useStyles()
    const [state, setState] = useState({
        engineeringDesignList: engineeringDesignList,
        operationsList: operationsList
    })

    useEffect(() => {
    }, [state.engineeringDesignList], [state.operationsList]);

    const hoverEvent = (text) => {
        setState((s) => ({...s, 
            engineeringDesignList: 
                s.engineeringDesignList.map(x => ({ ...x, hover: text ? text == x.text : false})),
            operationsList:
                s.operationsList.map(x => ({ ...x, hover: text ? text == x.text : false}))}))
    }

    return (
        <div>
            <div className={styles.logo}><img className= {styles.logoImage} src={kijenziLogo}/></div>
            <div className={styles.introductionText}>
                <a target="_blank" href='https://www.kijenzi.com/' style = {{color:style.linkColor}}> Kijenzi</a> is a social startup focusing on democratizing manufacturing in the developing world.
                I joined Kijenzi while it was still a research project at Penn State and continued after graduation as it became a company.
                Kijenzi primarily operates in Kisumu, Kenya but has manufactured products in use all over the world.
                Kijenzi uses predominantly 3D-printing to manufacture localized and customized products.
                In my time at Kijenzi I contributed to the development of over 200 unique manufacturing solutions,
                and the delivery of over 12,000 high-need products to clients in Kenya, Uganda, Sudan, Japan and more. 
                Below are some examples of key projects I led for Kijenzi.
            </div>
            <div className={styles.sectionText}>
                ENGINEERING DESIGN
                <div>
                    {state.engineeringDesignList.map((linkData,i) => {
                        const { text, link, image, hover } = linkData
                        return (
                            <a key={image} href={link}
                                style={{marginRight: (i % 2 === 0 && i !== 0)}}
                                onMouseEnter = {() => hoverEvent(text)}
                                onMouseLeave = {() => hoverEvent()}
                                >
                                <img className={styles.image} src={image} alt={''}  style = {{transform: linkData.hover ? 'scale(1.01)': 'scale(1)'}} />
                                <div>
                                    <input type='text' value = {text} className = {styles.descriptionText}/>
                                </div>
                            </a>
                        )
                    })}
                </div>
            </div>
            <div className={styles.sectionText}>
                COMPANY OPERATIONS
                <div>
                    {state.operationsList.map((linkData,i) => {
                        const { text, link, image, hover } = linkData
                        return (
                            <a key={image} href={link}
                                style={{marginRight: (i % 2 === 0 && i !== 0)}}
                                onMouseEnter = {() => hoverEvent(text)}
                                onMouseLeave = {() => hoverEvent()}
                                >
                                <img className={styles.image} src={image} alt={''}  style = {{transform: linkData.hover ? 'scale(1.01)': 'scale(1)'}} />
                                <div>
                                    <input type='text' value = {text} className = {styles.descriptionText}/>
                                </div>
                            </a>
                        )
                    })}
                </div>
            </div>
        </div>
    )
};

const Link = (text, link, image, hover) => {
    return {text, link, image, hover}
}
const engineeringDesignList = [
    Link('Smart Eye Camera Design Optimization and Manufacturing', '/kijenzi/sec', sec),
    Link('Fertilizer Scoop DFM','/kijenzi/scoop', scoop),
]

const operationsList = [
    Link('ERP Customization and Implementation', '/kijenzi/erpnext', erpnext),
    Link('Engineering and Manufacturing Cost Estimation', '/kijenzi/cost_estimations', costImage)
]

const style = styleDefaults()

const useStyles = createUseStyles({
    sectionText: {
        width: '95%',
        maxWidth:"1100px", 
        fontSize: style.headingFontSize,
        margin:'2% auto 30px auto',
        color: style.textColor,
        textShadow: style.textShadow,
        fontWeight: 700,
        //textAlign: 'center',
        textDecoration: 'none'
    },
    descriptionText: {
        textDecoration: 'none',
        width: '100%',
        height: '100%',
        //fontsize: '200pt',
        fontSize: style.bodyFontSize,
        backgroundColor: style.backgroundColor,
        border: 'none',
        margin: 'auto auto 3vh auto',
        color: style.textColor,
        textAlign: 'center',
    },
    image: {
        width: '100%',
       // height: 'calc(100vw*.2224923156)',
        height: 'calc(1100px*.3708)',
        maxHeight: 'calc(95vw*.3708)',
        margin: '10px auto auto auto',
        borderColor: style.borderColor,
        borderWidth: '2px',
        boxShadow: style.boxShadow,
        cursor:'pointer',
        transition: '0.5s',
        objectFit: 'cover',
        //objectPosition: '50% 10%'
    },
    title:{
        width: '100%',
        textAlign: 'center',
        fontSize: style.titleFontSize,
        margin: 'auto 10% 30px auto',
        color: style.textColor
    },
    introductionText:{
        width: '95%',
        maxWidth: '1100px',
        fontSize: style.bodyFontSize,
        margin: 'auto auto auto auto',
        textAlign: 'justify',
        color: style.textColor
    },
    logo:{
        width: '100%',
        textAlign: 'center',
        margin: '-40px auto -20px auto',
        transform: 'scale(0.5)'
    },
    logoImage:{
        width:'95vw'
    }

});

export default Kijenzi